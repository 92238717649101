<template>
  <section class="page_404">
    <div class="container">
      <div class="row">
        <div class="gif">
          <div class="col-sm-10 col-sm-offset-1 text-center">
            <img
              src="../../assets/images/svg/404.svg"
              class="four_zero_four_bg"
            />
            <div class="contant_box_404">
              <h3 class="h2">Ops!</h3>

              <p>
                Estamos com problemas em nossos servidores, por favor tente mais
                tarde! Se presistir, entre em contato com o time de atendimento
                contato@maatgestao.com.
              </p>

              <a href="#/main/dashboard">
                <b-button variant="primary">Voltar para a aplicação</b-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.page_404 {
  padding: 40px 0;
  background: $bg;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .gif {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.four_zero_four_bg {
  width: 100%;
  max-height: 404px;
  margin: 2em auto;
  object-fit: contain;
}
</style>
